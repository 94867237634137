<template>
  <EstimatedAnnualEnergyConsumptionRadioField
    :choices="choices"
    v-on="$listeners"
  />
</template>

<script>
import EstimatedAnnualEnergyConsumptionRadioField from 'chimera/all/components/form/fields/estimatedAnnualEnergyConsumption/EstimatedAnnualEnergyConsumptionRadioField'
import { solarPanelsPurchaseConsumerES } from 'chimera/solarPanels/service'
import { Selectable } from 'chimera/all/components/models/Selectable'

export default {
  name: 'EstimatedAnnualEnergyConsumptionFormPart',

  components: {
    EstimatedAnnualEnergyConsumptionRadioField,
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      corporateChoices: [
        new Selectable(
          'Menos de 10.000 KWh',
          'Menos de 10.000 KWh',
          'Menos de 10.000 KWh',
        ),
        new Selectable(
          'Entre 10.000 y 35.000 KWh',
          'Entre 10.000 y 35.000 KWh',
          'Entre 10.000 y 35.000 KWh',
        ),
        new Selectable(
          'Más de 35.000 KWh',
          'Más de 35.000 KWh',
          'Más de 35.000 KWh',
        ),
      ],

      consumerChoices: [
        new Selectable(
          '2.300 kWh (1 persona)',
          '2.300 kWh (1 persona)',
          '2.300 kWh (1 persona)',
        ),
        new Selectable(
          '3.400 kWh (2 personas)',
          '3.400 kWh (2 personas)',
          '3.400 kWh (2 personas)',
        ),
        new Selectable(
          '4.100 kWh (3 personas)',
          '4.100 kWh (3 personas)',
          '4.100 kWh (3 personas)',
        ),
        new Selectable(
          '4.600 kWh (4 personas)',
          '4.600 kWh (4 personas)',
          '4.600 kWh (4 personas)',
        ),
        new Selectable(
          '5.300 kWh (5 personas)',
          '5.300 kWh (5 personas)',
          '5.300 kWh (5 personas)',
        ),
        new Selectable(
          '5.400 kWh (6 personas)',
          '5.400 kWh (6 personas)',
          '5.400 kWh (6 personas)',
        ),
        new Selectable(
          'Más de 5.400 kWh',
          'Más de 5.400 kWh',
          'Más de 5.400 kWh',
        ),
        new Selectable(
          'No puedo hacer una estimación',
          'No puedo hacer una estimación',
          'No puedo hacer una estimación',
        ),
      ],
    }
  },

  computed: {
    /**
     * @returns {Selectable[]}
     */
    choices() {
      const service = this.$store.getters['lead/getData']('service')
      const consumerServices = [solarPanelsPurchaseConsumerES.id]
      if (consumerServices.includes(service)) {
        return this.consumerChoices
      }

      return this.corporateChoices
    },
  },
}
</script>
